import React from "react"
import Layout from "../Components/Layout"
import HomeComp from "../Components/Home"

export default function Home() {
  return (
    <Layout>
      <HomeComp />
    </Layout>
  )
}
