import React from "react"
import logo from "../assets/logo.png"
import "../styles/home.css"
import { Link } from "gatsby"

const Home = () => {
  return (
    <div className={"home"}>
      <div className={"hero"}>
        <span className={"hero__img"}>
          <img width={"100%"} src={logo} alt="logo" />
        </span>
        <span className={"hero__text"}>
          <h1>Mera Elvision AB</h1>
          <p>Din elektriker i Stockholm</p>
        </span>
      </div>

      <div className={"home__body"}>
        <p>
          Vi erbjuder alla typer av elinstallationer inom stockholmsområdet, ta
          gärna en titt på{" "}
          <Link className={"home__body__link"} to="/projekt">
            våra tidigare projekt
          </Link>{" "}
          eller{" "}
          <Link className={"home__body__link"} to="/kontakt">
            kontakta oss direkt
          </Link>{" "}
          för mer information.
        </p>
      </div>
    </div>
  )
}

export default Home
